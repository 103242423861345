import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import OrderIcon from './OrderIcon';
import CartIcon from './CartIcon';
import { useCartStore } from '../pages/store/cartStore';

export default function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { cart } = useCartStore();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick: EventListener = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    useCartStore.getState().getCart();

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="inline-flex items-center overflow-hidden rounded-md border-2 border-black bg-white">
        <button
          className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
          onClick={toggleDropdown}
        >
          <span className="sr-only">Menu</span>
          <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
          >
            <path
              d="m11 16.745c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-9-5c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm4-5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75s-.336.75-.75.75h-14.5c-.414 0-.75-.336-.75-.75z"
              fillRule="nonzero"
            />
          </svg>
          {cart.length > 0 && (
            <span className="absolute top-0 right-2 inline-flex items-center justify-center leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 px-0.5 text-lg font-semibold rounded-sm">
              {localStorage.getItem('cart') && cart.length}
            </span>
          )}
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-28 rounded-md border border-gray-100 bg-white shadow-lg"
          role="menu"
          style={{ bottom: 'calc(100% + 0.5rem)' }}
        >
          <div className="flex flex-col p-2 w-full gap-3">
            <OrderIcon />
            <CartIcon />
          </div>
        </div>
      )}

      {isOpen && (
        <>
          <div className="fixed inset-0 z-0" onClick={toggleDropdown}></div>
        </>
      )}
    </div>
  );
}
