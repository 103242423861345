import React from 'react';
import { FoodItem } from '../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import { getColor } from '../utils/CategoryManager';

interface OrderItemCardProps {
  item: FoodItem;
  isCompactLayout?: boolean;
  isStackedLayout?: boolean;
}

const OrderItemCard: React.FC<OrderItemCardProps> = ({
  item,
  isCompactLayout = false,
  isStackedLayout = false,
}) => {
  const navigate = useNavigate();

  const cardClasses = `flex items-start justify-center w-full max-w-md mx-auto rounded-lg overflow-hidden text-white ${
    isCompactLayout ? 'p-2' : 'p-4'
  } ${isStackedLayout ? 'flex-col' : ''}`;

  const imageClasses = `w-24 h-24 object-cover rounded-l-lg ${
    isCompactLayout ? 'w-16 h-16' : ''
  } ${isStackedLayout ? 'mb-2' : ''}`;

  const contentClasses = `flex flex-col flex-grow ${
    isCompactLayout ? 'p-2' : 'p-4'
  } ${isStackedLayout ? 'text-start' : ''}`;

  const nameClasses = `text-lg font-semibold ${
    isCompactLayout ? 'text-sm' : ''
  }`;

  const priceClasses = ` ${isCompactLayout ? 'text-sm' : ''}`;

  const descriptionClasses = `mt-1  ${isCompactLayout ? 'text-sm' : ''}`;

  const categoryClasses = `mt-1 px-2 py-1 text-sm font-semibold leading-tight rounded-md text-center w-fit bg-white text-black`;

  const goCustomizeItem = (item: FoodItem) => {
    navigate('/order/customization', {
      state: { order: item },
    });
  };

  return (
    <div className={cardClasses} onClick={() => goCustomizeItem(item)}>
      <img
        src={
          item.image.includes('imgs/')
            ? item.image
            : process.env.REACT_APP_IMAGE_API_URL + item.image
        }
        alt={item.name}
        className={imageClasses}
      />
      <div className={contentClasses}>
        <h3 className={nameClasses}>{item.name}</h3>
        {isStackedLayout ? (
          <div className="flex flex-col justify-start">
            <p className={priceClasses}>
              {item.price > 0 && `$${(item.price / 100).toFixed(2)}`}
            </p>
            <p className={categoryClasses}>{item.category}</p>
          </div>
        ) : (
          <>
            <p className={priceClasses}>
              {' '}
              {item.price > 0 && `$${(item.price / 100).toFixed(2)}`}
            </p>
            <p className={categoryClasses}>{item.category}</p>
          </>
        )}
        <p className={descriptionClasses}>{item.description}</p>
      </div>
    </div>
  );
};

export default OrderItemCard;
