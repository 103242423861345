import { generateRandomPastelBackground } from './utils';

export const tags = [
  {
    name: 'Baked Goods & Pastries',
    color: 'bg-gradient-to-r from-red-300 to-yellow-300',
    recommendations: ['Hot Coffee', 'Hot Latte Flavors', 'Juices'],
  },
  {
    name: 'Breakfast',
    color: 'bg-gradient-to-r from-orange-300 to-yellow-300',
    recommendations: ['Hot Coffee', 'Hot Tea', 'Juices'],
  },
  {
    name: 'Choice of Side',
    color: 'bg-gradient-to-r from-amber-300 to-yellow-300',
    recommendations: ['Baked Goods & Pastries', 'Cold Drinks', 'Dessert'],
  },
  {
    name: 'Cold Drinks',
    color: 'bg-gradient-to-r from-lime-300 to-green-300',
    recommendations: ['Hot Coffee', 'Hot Latte Flavors', 'Juices'],
  },
  {
    name: 'Cold Cuts',
    color: 'bg-gradient-to-r from-yellow-400 to-red-500',
    recommendations: ['Hamburgers', 'Signature Salads', 'Signature Wraps'],
  },
  {
    name: 'Create Your Own',
    color: 'border-2 border-yellow-300 text-yellow-300',
    recommendations: ['From the Grill', 'Signature Salads', 'Signature Wraps'],
  },
  {
    name: 'Dessert',
    color: 'bg-gradient-to-r from-green-300 to-blue-500',
    recommendations: [
      'Baked Goods & Pastries',
      'Cold Drinks',
      'Energy Infused Red Bull',
    ],
  },
  {
    name: 'Energy Infused Red Bull',
    color: 'bg-gradient-to-r from-green-300 to-blue-500',
    recommendations: ['Dessert', 'From the Grill', 'Frozen Drinks'],
  },
  {
    name: 'From the Grill',
    color: 'bg-gradient-to-r from-emerald-300 to-green-300',
    recommendations: ['Create Your Own', 'Signature Salads', 'Signature Wraps'],
  },
  {
    name: 'Frozen Drinks',
    color: 'bg-gradient-to-r from-teal-300 to-blue-300',
    recommendations: ['Energy Infused Red Bull', 'Cold Drinks', 'Dessert'],
  },
  {
    name: 'Hamburgers',
    color: 'bg-gradient-to-r from-cyan-300 to-blue-300',
    recommendations: ['Cold Cuts', 'Signature Salads', 'Signature Wraps'],
  },
  {
    name: 'Hot Coffee',
    color: 'bg-gradient-to-r from-blue-300 to-indigo-300',
    recommendations: ['Baked Goods & Pastries', 'Breakfast', 'Cold Drinks'],
  },
  {
    name: 'Hot Latte Flavors',
    color: 'bg-gradient-to-r from-indigo-300 to-purple-300',
    recommendations: ['Baked Goods & Pastries', 'Breakfast', 'Cold Drinks'],
  },
  {
    name: 'Hot Tea',
    color: 'bg-gradient-to-r from-purple-300 to-pink-300',
    recommendations: ['Baked Goods & Pastries', 'Breakfast', 'Cold Drinks'],
  },
  {
    name: 'Iced Latte Flavors',
    color: 'bg-gradient-to-r from-pink-300 to-red-300',
    recommendations: ['Baked Goods & Pastries', 'Breakfast', 'Cold Drinks'],
  },
  {
    name: 'Juices',
    color: 'bg-gradient-to-r from-fuchsia-300 to-pink-300',
    recommendations: ['Baked Goods & Pastries', 'Breakfast', 'Cold Drinks'],
  },
  {
    name: 'Signature Salads',
    color: 'bg-gradient-to-r from-green-400 to-yellow-500',
    recommendations: ['Cold Cuts', 'Create Your Own', 'From the Grill'],
  },
  {
    name: 'Signature Wraps',
    color: 'bg-gradient-to-r from-gray-300 to-gray-500',
    recommendations: ['Cold Cuts', 'Create Your Own', 'From the Grill'],
  },
  {
    name: 'Smoothies',
    color: 'bg-gradient-to-tr from-lime-300 to-green-300',
    recommendations: ['Cold Drinks', 'Dessert', 'Frozen Drinks'],
  },
];

export const getColor = (category: string, white?: false): string => {
  // const tag = tags.find((t) => t.name.includes(category));
  // if (tag) {
  //   return tag.color;
  // }
  const tagColor = generateRandomPastelBackground();
  return white ? '#ffffff' : tagColor;
};
