import { motion } from 'framer-motion';
import { FoodItem } from '../interfaces/interfaces';
import { useEffect, useState } from 'react';
import { convertCentsToDollars, getTotalPriceOfItem } from '../utils/utils';

type OrderItemProps = {
  item: FoodItem;
};

const OrderItem: React.FC<OrderItemProps> = ({ item }) => {
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const itemVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    // get total price
    setTotalPrice((totalPrice) => {
      let price = Number(item.price);
      Object.values(item.selectedAddons).forEach((addon: any) => {
        price += addon[0].price;
      });
      return price;
    });
  }, []);

  return (
    <motion.div
      className="flex items-center justify-between mb-2"
      variants={itemVariants}
      key={item.id}
    >
      <div className="flex items-center">
        {/* image */}
        <img
          src={item.image}
          alt={item.name}
          className="w-16 h-16 object-contain mr-4"
        />

        {/* name and quantity*/}
        <div>
          <h1 className="text-lg font-bold text-ellipsis">{item.name}</h1>
          {item.quantity > 1 && (
            <p className="text-sm">Quantity: {item.quantity || 1}</p>
          )}
          {/* loops selectAddons within item*/}
          {Object.values(item.selectedAddons).map((addon: any) => {
            return (
              <div className="flex gap-2" key={addon[0].id}>
                {/* <p className="text-sm text-start">
                      <span className="font-semibold">Addons:</span>{' '}
                      {addon[0].name}
                    </p> */}
                <p className="text-sm text-start">
                  <span className="font-semibold">Item:</span> {addon[0].item}
                </p>
                {addon[0].price > 0 && (
                  <p className="text-sm text-start">
                    <span className="font-semibold">Price:</span> $
                    {convertCentsToDollars(addon[0].price)}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* price */}
      <p className="text-lg font-bold">
        $
        {convertCentsToDollars(
          getTotalPriceOfItem(Number(item.price), item.selectedAddons)
        )}
      </p>
    </motion.div>
  );
};

export default OrderItem;
