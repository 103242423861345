import { useEffect, useState } from 'react';
import { getPaymentStatus, postOrder } from '../utils/utils';
import { useCartStore } from './store/cartStore';
import Timer from '../components/Timer';
import { convertCentsToDollars } from '../utils/convertCentsToDollars';
import { motion } from 'framer-motion';
import Button from '../components/Button';
import OrderItem from '../components/OrderItem';

export default function OrderConfirmation() {
  const [orderId, setOrderId] = useState<string>('');
  const [paymentId, setPaymentId] = useState<string>('');
  const [paymentStatus, setPaymentStatus] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    useCartStore.getState().getCart();
    getPaymentStatus(localStorage.getItem('orderId') || '').then((res) => {
      try {
        setOrderId(res.order_id);
        setPaymentId(res.payment_id);
        setPaymentStatus(res.payment_status);

        if (localStorage.getItem('message')) {
          setMessage(JSON.parse(localStorage.getItem('message') || '') || '');
        }

        // set payment_status to local storage
        localStorage.setItem('paymentStatus', res.payment_status);

        useCartStore.getState().cart.length > 0 &&
          postOrder(res.order_id, useCartStore.getState().cart, message);
      } catch (error) {}
    });
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="flex flex-col min-h-screen items-center justify-center bg-dark-black text-white pb-5"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* timer */}
      <Timer countdown={900} />

      {/* payment completed */}
      <motion.div
        className=" p-8 rounded-sm border-2 border-black"
        variants={itemVariants}
      >
        <h1 className="text-3xl font-bold mb-4">Thank you for your order!</h1>
        <p className="text-lg mb-2">
          Your order has been confirmed and is being processed.
        </p>
        {/* <div className="mb-2">
          <span className="font-bold">Order ID:</span> {orderId}
          </div>
          <div className="mb-2">
          <span className="font-bold">Payment ID:</span> {paymentId}
        </div> */}
        <div className="mb-4">
          <span className="font-bold">Payment Status:</span>{' '}
          <span className="bg-green-400 px-4 py-2 rounded-sm font-semibold">
            {paymentStatus || 'Pending'}
          </span>
        </div>
        <p className="text-lg"></p>
      </motion.div>

      {/* loop cart */}
      <motion.div
        className=" p-8 rounded-sm border-2 border-black mt-8"
        variants={itemVariants}
      >
        <h1 className="text-3xl font-bold mb-4">Order Details</h1>
        {useCartStore.getState().cart.length > 0 &&
          useCartStore.getState().cart.map((item) => {
            return <OrderItem key={item.id} item={item} />;
          })}
      </motion.div>

      {/* go back to order page */}
      <motion.div className="pt-6">
        <Button name="Menu" navigateTo="/order" />
      </motion.div>
    </motion.div>
  );
}
