import { motion } from 'framer-motion';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import { useCartStore } from './store/cartStore';
import { useEffect } from 'react';

export default function OrderComplete() {
  const orderId = localStorage.getItem('orderId');
  const { clearCart } = useCartStore();

  useEffect(() => {
    if (orderId) {
      clearCart();
      localStorage.removeItem('orderId');
    }
  }, [orderId]);

  return (
    <>
      <div className="fixed bottom-5 right-5">
        <Dropdown />
      </div>
      <div className="flex flex-col items-center pt-16 h-screen bg-dark-black">
        <motion.h1
          className="text-4xl font-bold text-green-500 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Order Complete
        </motion.h1>
        {orderId && (
          <motion.p
            className="text-xl text-center mb-8"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Your order ID is: {orderId}
          </motion.p>
        )}
        {!orderId && (
          <motion.p
            className="text-xl text-center mb-8"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Thank you for your order!
          </motion.p>
        )}
        <Button navigateTo="/order" name="Back to Menu" />
      </div>
    </>
  );
}
