import React, { useState, useEffect, useRef } from 'react';
import { useMenuStore } from '../pages/store/menuStore';
import { set } from 'lodash';

interface Tag {
  name: string;
}

interface SearchFilterProps {
  tags?: Tag[];
}

const SearchFilter: React.FC<SearchFilterProps> = ({ tags }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { setFilter, getFilter, filter } = useMenuStore();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick: EventListener = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleRadioChange = (selectedTag: string) => {
    if (filter === selectedTag) {
      // Uncheck the radio button if it is already checked
      setFilter('');
    } else {
      setFilter(selectedTag);
    }

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getFilter();
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="inline-flex items-center overflow-hidden rounded-md border-2 border-black bg-white">
        <button
          className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
          onClick={toggleDropdown}
        >
          <span className="sr-only">Menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="w-6 h-6"
          >
            <path d="M19.479 2l-7.479 12.543v5.924l-1-.6v-5.324l-7.479-12.543h15.958zm3.521-2h-23l9 15.094v5.906l5 3v-8.906l9-15.094z" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute -right-[100%] z-40 rounded-md border border-gray-100 bg-dark-black shadow-lg text-white"
          role="menu"
          style={{
            bottom: 'calc(100% + 0.5rem)',
            display: 'flex',
            justifyContent: 'center',
            width: 'auto',
          }}
        >
          <div className="w-80 p-2 gap-3 grid grid-cols-2">
            {/* loop and display tags */}
            {tags?.map((tag) => (
              <div className="flex items-center gap-2" key={tag.name}>
                <input
                  type="radio"
                  id={tag.name}
                  name="selectedTag"
                  className="w-4 h-4 border border-gray-300 rounded-sm"
                  checked={filter === tag.name}
                  onClick={() => {
                    // if checked, uncheck
                    if (filter === tag.name) {
                      setFilter('');
                    }
                  }}
                  onChange={() => {
                    handleRadioChange(tag.name);
                  }}
                />
                <label
                  htmlFor={tag.name}
                  className="text-sm font-semibold overflow-hidden"
                  style={{ maxWidth: 'calc(100% - 1.5rem)' }}
                  title={tag.name}
                >
                  {tag.name}
                </label>
              </div>
            ))}
          </div>
          <style>
            {`
      @media screen and (max-width: 768px) {
        .grid-cols-3 {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    `}
          </style>
        </div>
      )}

      {isOpen && (
        <>
          <div className="fixed inset-0 z-0" onClick={toggleDropdown}></div>
        </>
      )}
    </div>
  );
};

export default SearchFilter;
