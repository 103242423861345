import { motion, AnimatePresence } from 'framer-motion';
import React, { useState, useRef } from 'react';

interface OrderIconProps {
  count?: number;
}

const OrderIcon: React.FC<OrderIconProps> = ({ count }) => {
  const [isClicked, setIsClicked] = useState(false);
  const countRef = useRef(count || 0);

  const handleClick = () => {
    if (count) {
      setIsClicked(!isClicked);
      countRef.current++;
    }
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const clickVariants = {
    clicked: { scale: [1, 1.2, 1], transition: { duration: 0.5 } },
    notClicked: { scale: 1 },
  };

  return (
    <div className="relative flex items-center text-black">
      <a href="/order" about="order" onClick={handleClick}>
        <div className="flex flex-row justify-center items-center gap-1">
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            variants={clickVariants}
            animate={isClicked ? 'clicked' : 'notClicked'}
            key={countRef.current}
          >
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M12-.006c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm5.999 19.999c2.429-1.825 4.001-4.73 4.001-7.999 0-5.519-4.481-10-10-10s-10 4.481-10 10c0 3.701 2.015 6.936 5.008 8.665v-4.023c0-.576-.36-.765-1.147-1.395-.581-.466-.981-1.194-.907-1.935.209-2.114.718-6.312.718-6.312h.5v5h.836l.186-5h.506l.144 5h.836l.201-5h.469l.166 5h.835v-5h.458s.562 4.171.793 6.292c.081.751-.341 1.493-.935 1.963-.791.626-1.151.806-1.151 1.391v5.042c.794.204 1.626.312 2.484.312 1.229 0 2.407-.222 3.496-.629v-3.371s-.977-.003-2.056 0c.668-5.83 2.586-11 3.883-11 .373 0 .67.297.673.709.005.802.004 7.091.003 12.29z" />
            </svg>
          </motion.svg>
          <p className="font-semibold">Order</p>
        </div>
        <AnimatePresence>
          {count && count > 0 && (
            <motion.span
              className="absolute top-5 right-2 transform translate-x-1/2 -translate-y-1/2 px-1 text-xs font-semibold rounded-sm bg-red-600 text-white"
              key="count"
              variants={item}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              {count}
            </motion.span>
          )}
        </AnimatePresence>
      </a>
    </div>
  );
};

export default OrderIcon;
