import { useEffect, useState } from 'react';
import Menu from '../components/Menu';
import SearchBar from '../components/SearchBar';

import { tags } from '../utils/CategoryManager';
import Dropdown from '../components/Dropdown';
import SearchFilter from '../components/SearchFilter';
import { useMenuStore } from './store/menuStore';

export default function Order() {
  const { filter, getFilter } = useMenuStore();
  const [searchResult, setSearchResult] = useState<string>('');
  const [tag, setTag] = useState<string>(filter);

  useEffect(() => {
    getFilter();
    setTag(filter);

    // check if payment is successful
    if (localStorage.getItem('paymentStatus') === 'COMPLETED') {
      // clear cart
      localStorage.removeItem('cart');
      localStorage.removeItem('orderId');
      localStorage.removeItem('message');
      localStorage.removeItem('paymentStatus');
    }
  }, []);

  useEffect(() => {
    setTag(filter);
    setSearchResult('');
  }, [filter]);

  useEffect(() => {
    if (searchResult.length > 0) {
      // scroll to top
      window.scrollTo(0, 0);
    }
  }, [searchResult]);

  return (
    <div className="flex flex-col gap-5 h-screen bg-dark-black">
      <div className="">
        <div
          className={`flex flex-row items-center justify-center fixed bottom-5 w-full gap-2`}
        >
          <SearchBar
            setSearchResult={setSearchResult}
            searchResult={searchResult}
          />
          <div className="flex flex-row gap-0.5">
            <SearchFilter tags={tags} />
            <Dropdown />
          </div>
        </div>
        <div className="flex flex-col gap-2 px-4 pb-24 bg-dark-black">
          <Menu searchResult={searchResult} tag={tag} production={true} />
        </div>
      </div>
    </div>
  );
}
