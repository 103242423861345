import { motion, AnimatePresence } from 'framer-motion';
import React, { useState, useRef, useEffect } from 'react';
import { useCartStore } from '../pages/store/cartStore';

const CartIcon = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const clickVariants = {
    clicked: { scale: [1, 1.2, 1], transition: { duration: 0.5 } },
    notClicked: { scale: 1 },
  };

  useEffect(() => {
    useCartStore.getState().getCart();
  }, []);

  return (
    <div className="relative flex items-center text-black">
      <a href="/cart" about="cart" onClick={handleClick}>
        <div className="flex flex-row justify-center items-center gap-1">
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            variants={clickVariants}
            animate={isClicked ? 'clicked' : 'notClicked'}
          >
            <path d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-10.563-5l-2.937-7h16.812l-1.977 7h-11.898zm11.233-5h-11.162l1.259 3h9.056l.847-3zm5.635-5l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z" />
          </motion.svg>
          <p className="font-semibold">Cart</p>
          <AnimatePresence>
            {useCartStore.getState().cart.length > 0 && (
              <motion.span
                className="px-0.5 text-[0.65rem] font-semibold rounded-sm bg-red-600 text-white"
                key="count"
                variants={item}
                initial="hidden"
                animate="show"
                exit="hidden"
              >
                {useCartStore.getState().cart.length}
              </motion.span>
            )}
          </AnimatePresence>
        </div>
      </a>
    </div>
  );
};

export default CartIcon;
