import React, { useEffect, useState } from 'react';
import { motion, useSpring } from 'framer-motion';
import { useCartStore } from '../pages/store/cartStore';
import { FoodItem } from '../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';

interface Props {
  order?: FoodItem;
  navigateTo?: string; // Optional route to navigate to
  name?: string; // Optional name to display on button
  resetAnimation?: boolean; // Optional prop to reset the animation
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  toggle?: Function;
  isDisabled?: boolean;
}

const Button = (props: Props) => {
  const { addToCart, getCart } = useCartStore();
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const springProps = useSpring(0, { stiffness: 200, damping: 20 });

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    props.onClick && props.onClick(event);

    setIsClicked(true);
    // set timer to reset the animation
    setTimeout(() => {
      setIsClicked(false);
      springProps.set(0);
    }, 700);

    props.order && addToCart(props.order);

    props.toggle && setTimeout(() => props.toggle!(), 500);

    props.navigateTo &&
      setTimeout(() => {
        navigate(props.navigateTo!);
      }, 700);
  };

  const checkmarkVariants = {
    initial: { scale: 0 },
    animate: { scale: 1 },
  };

  useEffect(() => {
    getCart();
  }, []);

  return (
    <motion.button
      className={`${
        props.isDisabled ? 'opacity-50' : ''
      } w-full max-w-md text-slate-200 shadow-neutral-200 font-bold py-2 px-4 rounded bg-slate-800 ${
        props.order && props.order.addon.length > 0 ? 'mt-8' : ''
      }`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      onClick={props.isDisabled ? () => {} : handleButtonClick}
      style={{
        backgroundColor: isClicked ? '' : '',
        width: isClicked ? springProps.get() * 200 + 200 : 'auto',
        scale: isClicked ? 0.9 : 1,
        transition: 'width 0.5s, scale 0.5s',
      }}
    >
      {isClicked ? (
        <motion.span
          className="inline-flex items-center"
          variants={checkmarkVariants}
          initial="initial"
          animate="animate"
          style={{ color: '#1C1917' }}
        >
          &#10003;
        </motion.span>
      ) : (
        props.name || 'Add to Cart'
      )}
    </motion.button>
  );
};

export default Button;
