import React, { useEffect } from 'react';
import './App.css';

// pages
import Home from './pages/Home';

// custom imports
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Order from './pages/Order';
import CustomizeOrder from './pages/CustomizeOrder';
import Cart from './pages/Cart';
import Navbar from './components/Navbar';
import OrderComplete from './pages/OrderComplete';
import { useMenuStore } from './pages/store/menuStore';
import ProtectedAdminDashboard from './pages/ProtectedAdminDashboard';
import OrderConfirmation from './pages/OrderConfirmation';
import EditFoodItem from './pages/EditFoodItem';
// import BeRightBack from './components/BeRightBack';

function App() {
  const password = process.env.REACT_APP_ADMIN_PWD || '1';
  useEffect(() => {
    useMenuStore.getState().getMenu();
    // check if payment is successful
    if (localStorage.getItem('paymentStatus') === 'COMPLETED') {
      // clear cart
      localStorage.removeItem('cart');
      localStorage.removeItem('orderId');
      localStorage.removeItem('message');
      localStorage.removeItem('paymentStatus');
    }
  }, []);

  useEffect(() => {}, []);
  return (
    <div className="">
      <Navbar />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Comment out other routes during maintenance */}
          <Route path="/order" element={<Order />} />
          <Route path="/order/complete" element={<OrderComplete />} />
          <Route path="/order/customization" element={<CustomizeOrder />} />
          <Route path="/order/edit/:id" element={<EditFoodItem />} />
          <Route path="/cart" element={<Cart />} />
          <Route
            path="/cart/successful/order/confirmation"
            element={<OrderConfirmation />}
          />
          <Route
            path="/tgKi3D8&!7yHkiSl88Hs7THeSS"
            element={<ProtectedAdminDashboard password={password} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
