import React, { useState } from 'react';
import { useMenuStore } from './store/menuStore';
import Navbar from '../components/Navbar';
import Dropdown from '../components/Dropdown';
import { motion } from 'framer-motion';

export default function Home() {
  return (
    <div className="flex justify-center items-center bg-dark-black">
      <div className="flex flex-col items-center justify-center text-white pt-5">
        <div className="md:w-[75%]">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="flex flex-col gap-5 w-full max-w-screen-lg px-4"
          >
            {/* top image lunch truck */}
            <div className="w-full">
              <img
                src="./imgs/food-truck.jpg"
                alt="lunch truck"
                className="mb-4 w-full rounded-md"
              />
            </div>

            {/* name */}
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-3xl font-bold">Javi's Coffee Bar</h1>
              <p className="text-xl font-semibold">Fresh Food & Drinks</p>
            </div>

            {/* call button */}
            <div className="flex w-full justify-between items-center mb-4 text-xl font-bold gap-4 px-8">
              <a href="tel:9082664850" title="phone">
                <div className="flex flex-col justify-center items-center">
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    className="fill-current text-green-500"
                  >
                    <path d="M16 22.621l-3.521-6.795c-.007.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.082-1.026-3.492-6.817-2.106 1.039c-1.622.845-2.298 2.627-2.289 4.843.027 6.902 6.711 18.013 12.212 18.117.575.011 1.137-.098 1.677-.345.121-.055 2.102-1.029 2.11-1.033zm4-5.621h-1v-13h1v13zm-2-2h-1v-9h1v9zm4-1h-1v-7h1v7zm-6-1h-1v-5h1v5zm-2-1h-1v-3h1v3zm10 0h-1v-3h1v3zm-12-1h-1v-1h1v1z" />
                  </svg>
                  <p>Call</p>
                </div>
              </a>
              <a
                href="/order"
                className="flex flex-col justify-center items-center"
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  className="fill-current text-yellow-500"
                >
                  <path d="M12-.006c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm5.999 19.999c2.429-1.825 4.001-4.73 4.001-7.999 0-5.519-4.481-10-10-10s-10 4.481-10 10c0 3.701 2.015 6.936 5.008 8.665v-4.023c0-.576-.36-.765-1.147-1.395-.581-.466-.981-1.194-.907-1.935.209-2.114.718-6.312.718-6.312h.5v5h.836l.186-5h.506l.144 5h.836l.201-5h.469l.166 5h.835v-5h.458s.562 4.171.793 6.292c.081.751-.341 1.493-.935 1.963-.791.626-1.151.806-1.151 1.391v5.042c.794.204 1.626.312 2.484.312 1.229 0 2.407-.222 3.496-.629v-3.371s-.977-.003-2.056 0c.668-5.83 2.586-11 3.883-11 .373 0 .67.297.673.709.005.802.004 7.091.003 12.29z" />
                </svg>
                <p>Order</p>
              </a>
              <a
                href="/order"
                className="flex flex-col justify-center items-center"
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  className="fill-current text-blue-500"
                >
                  <path d="M14.209 22c.09 1.185-.293 1.99-2.468 2-4.083.019-4.441 0-8.524 0-2.207 0-2.521-.793-2.435-2h13.427zm6.791 2h-5.717c.423-.691.541-1.665.38-2.365l.004-.004c1.098-1.187 1.089-2.974.107-4.142.513-1.464-.89-5.941-6.443-6.844l-.331-2.645h14l-2 16zm-7.291-3c-.006 0-12.482-.002-12.506-.006-.674-.068-1.203-.711-1.203-1.494 0-.782.529-1.426 1.203-1.494.024-.004 1.318-.008 1.318-.008 2.088.018 2.227 1.639 3.256 1.633 1.028-.006 1.298-1.607 3.223-1.631h4.709c.715.019 1.291.684 1.291 1.5 0 .817-.576 1.482-1.291 1.5zm.647-4h-13.734c0-2.493 2.636-5 6.878-5s6.856 2.507 6.856 5zm9.644-10h-16v-1.382c.87 0 2-.697 2-1.618h6.185l2.072-4 1.359.634-1.776 3.366h4.199c0 .922 1.092 1.618 1.961 1.618v1.382z" />
                </svg>
                <p>Menu</p>
              </a>
            </div>

            {/* Banner */}
            <a href=" https://www.instagram.com/stories/javiscoffeebar/">
              <div className="w-full bg-gradient-to-tr from-purple-400 to-pink-500 text-white text-center p-4 rounded-lg">
                <h1 className="text-2xl font-bold">
                  Checkout the Daily Specials
                </h1>
                <p className="text-sm">
                  (call only or order in person for daily specials)
                </p>
              </div>
            </a>

            {/* about photos */}
            <div className="w-full grid grid-cols-3 gap-4 font-semibold">
              <div className="">
                <a href="/order">
                  <img
                    src={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/1.gif?${Date.now()}`}
                    alt="smoothies"
                    className="w-full rounded-md h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = e.currentTarget.src.match(/gif/)
                        ? `https://javiscoffeebar-homepage-images.s3.amazonaws.com/1.png?${Date.now()}`
                        : `https://javiscoffeebar-homepage-images.s3.amazonaws.com/1.jpeg?${Date.now()}`;
                    }}
                  />
                </a>
              </div>
              <div className="">
                <a href="/order">
                  <img
                    src={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/2.gif?${Date.now()}`}
                    alt="smoothies"
                    className="w-full rounded-md h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = e.currentTarget.src.match(/gif/)
                        ? `https://javiscoffeebar-homepage-images.s3.amazonaws.com/2.png?${Date.now()}`
                        : `https://javiscoffeebar-homepage-images.s3.amazonaws.com/2.jpeg?${Date.now()}`;
                    }}
                  />
                </a>
              </div>
              <div className="">
                <a href="/order">
                  <img
                    src={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/3.gif?${Date.now()}`}
                    alt="smoothies"
                    className="w-full rounded-md h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = e.currentTarget.src.match(/gif/)
                        ? `https://javiscoffeebar-homepage-images.s3.amazonaws.com/3.png?${Date.now()}`
                        : `https://javiscoffeebar-homepage-images.s3.amazonaws.com/3.jpeg?${Date.now()}`;
                    }}
                  />
                </a>
              </div>
              <div className="col-span-2">
                <a href="/order">
                  <img
                    src={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/4.gif?${Date.now()}`}
                    alt="smoothies"
                    className="w-full rounded-md h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = e.currentTarget.src.match(/gif/)
                        ? `https://javiscoffeebar-homepage-images.s3.amazonaws.com/4.png?${Date.now()}`
                        : `https://javiscoffeebar-homepage-images.s3.amazonaws.com/4.jpeg?${Date.now()}`;
                    }}
                  />
                </a>
              </div>
              <div className="flex flex-col justify-center items-center bg-gradient-to-br from-orange-400 to-red-500 rounded-md font-semibold">
                <p className="mt-2 text-center p-2">
                  We take pride in crafting our food with care and attention to
                  detail.
                </p>
              </div>
              <div className="">
                <a href="/order">
                  <img
                    src={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/5.gif?${Date.now()}`}
                    alt="smoothies"
                    className="w-full rounded-md h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = e.currentTarget.src.match(/gif/)
                        ? `https://javiscoffeebar-homepage-images.s3.amazonaws.com/5.png?${Date.now()}`
                        : `https://javiscoffeebar-homepage-images.s3.amazonaws.com/5.jpeg?${Date.now()}`;
                    }}
                  />
                </a>
              </div>
              <div className="flex flex-col justify-center items-center bg-gradient-to-tl from-indigo-400 to-emerald-500 rounded-md font-semibold">
                <p className="mt-2 text-center p-2">
                  Our food is made with the freshest ingredients and prepared
                  with love.
                </p>
              </div>
              <div className="">
                <a href="/order">
                  <img
                    src={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/6.gif?${Date.now()}`}
                    alt="smoothies"
                    className="w-full rounded-md h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = e.currentTarget.src.match(/gif/)
                        ? `https://javiscoffeebar-homepage-images.s3.amazonaws.com/6.png?${Date.now()}`
                        : `https://javiscoffeebar-homepage-images.s3.amazonaws.com/6.jpeg?${Date.now()}`;
                    }}
                  />
                </a>
              </div>
              <div className=" col-span-2">
                <a href="/order">
                  <img
                    src={`https://javiscoffeebar-homepage-images.s3.amazonaws.com/7.gif?${Date.now()}`}
                    alt="smoothies"
                    className="w-full rounded-md h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = e.currentTarget.src.match(/gif/)
                        ? `https://javiscoffeebar-homepage-images.s3.amazonaws.com/7.png?${Date.now()}`
                        : `https://javiscoffeebar-homepage-images.s3.amazonaws.com/7.jpeg?${Date.now()}`;
                    }}
                  />
                </a>
              </div>
              <div className="flex flex-col justify-center items-center bg-gradient-to-b from-rose-400 to-fuchsia-500 rounded-md font-semibold">
                <p className="mt-2 text-center p-2">
                  We believe that good food brings people together and creates
                  memories.
                </p>
              </div>
            </div>

            {/* social media links */}
            <div className="w-full mt-auto">
              <div className="rounded-md p-2 inline-block w-full">
                <div className="flex justify-between">
                  <p className="font-semibold"></p>
                  <div className="flex justify-end gap-2">
                    <a
                      href="https://www.facebook.com/people/Javis-coffee-bar/100063463005046"
                      title="facebook"
                      className="bg-white rounded-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className="fill-current text-blue-600"
                      >
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
                      </svg>
                    </a>
                    <a
                      href="https://www.instagram.com/javiscoffeebar/"
                      title="instagram"
                      className="bg-white rounded-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className="fill-current text-pink-600"
                      >
                        <path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" />
                      </svg>
                    </a>
                    <a
                      href="https://twitter.com/javiscoffeebar"
                      title="Twitter"
                    >
                      <svg
                        viewBox="0 0 1200 1227"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
