import { useEffect, useState, useRef } from 'react';

interface TimerProps {
  countdown: number;
}

export default function Timer({ countdown }: TimerProps) {
  const [timeLeft, setTimeLeft] = useState<number>(countdown);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerRef.current as NodeJS.Timeout);
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  if (timeLeft <= 0) {
    clearInterval(timerRef.current as NodeJS.Timeout);
  }

  return (
    <div className=" p-8 rounded-sm text-center text-white">
      <h1 className="text-3xl font-bold mb-4">Time Left:</h1>
      <div className="text-2xl font-bold mb-2">
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </div>
    </div>
  );
}
