import React from 'react';
import { motion } from 'framer-motion'; // Assuming you have a separate types file
import { FoodItem } from '../interfaces/interfaces';
import OrderItemCard from './OrderItemCard';
import { random } from 'lodash';

interface RecommendationsProps {
  items: FoodItem[];
}

const Recommendations: React.FC<RecommendationsProps> = ({ items }) => {
  // randomize items
  items.sort(() => Math.random() - 0.5);
  const firstFourItems = items.slice(0, 4); // Only include the first 4 items in the array

  return (
    <div className=" border-2 border-black rounded-lg shadow-md p-4 w-full">
      <h2 className="text-lg font-medium mb-4">You might also enjoy😋</h2>
      <div className="grid grid-cols-2 gap-4">
        {firstFourItems.map((item, index) => (
          <div key={item.id}>
            <OrderItemCard
              item={item}
              isCompactLayout={true}
              isStackedLayout={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
