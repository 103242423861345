import React, { useEffect, useState } from 'react';
import { Addon, AddonSelection } from '../interfaces/interfaces';
import { convertCentsToDollars } from '../utils/convertCentsToDollars';

interface AddonProps {
  addon: Addon;
  selectedAddons?: Record<string, AddonSelection[]>;
  handleAddonClick: (
    addonName: string,
    itemName: string,
    itemPrice: number
  ) => void;
}

const AddonComponent: React.FC<AddonProps> = ({
  addon,
  handleAddonClick,
  selectedAddons,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // loop through selectedAddons and add to selectedItems if name matches addon.name
  useEffect(() => {
    if (selectedAddons) {
      Object.entries(selectedAddons).map(([key, value]: any) => {
        if (key === addon.name) {
          setSelectedItems((prev) => [
            ...prev,
            ...value.map((item: any) => item.item),
          ]);
        }
      });
    }
  }, []);

  const handleCheckboxChange = (itemName: string, itemPrice: number) => {
    if (selectedItems.includes(itemName)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemName) || []);
    } else if (
      addon.limit === undefined ||
      selectedItems.length < addon.limit
    ) {
      setSelectedItems([...selectedItems, itemName]);
    }
    // Pass both the name and the price of the item to the handler function
    handleAddonClick(addon.name, itemName, itemPrice);
  };

  if (addon.type === 'single') {
    return (
      <div className="py-2 text-white">
        <div className="flex flex-row items-center gap-2">
          <div className="text-lg font-semibold ">{addon.name}</div>
          {addon.limit && addon.limit !== 0 && (
            <div className="text-sm pt-1">
              limit: {selectedItems.length}/{addon.limit}
            </div>
          )}
        </div>
        <div className="mt-2">
          {Object.keys(addon.data).map((key: string, keyIndex: number) => (
            <div key={keyIndex} className="mb-2">
              <input
                type="checkbox"
                id={key}
                name={key}
                value={key}
                onChange={() => handleCheckboxChange(key, addon.data[key])}
                checked={selectedItems.includes(key)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />

              <label htmlFor={key} className="ml-2">
                {key}
                {convertCentsToDollars(addon.data[key]) === '0.00'
                  ? ''
                  : ` + ($${convertCentsToDollars(addon.data[key])})`}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  } else if (addon.type === 'multi') {
    return (
      <div className="py-2 text-white">
        <div className="flex flex-row items-center gap-2">
          <div className="text-lg font-semibold ">{addon.name}</div>
          {addon.limit && addon.limit !== 0 && (
            <div className="text-sm pt-1">
              limit: {selectedItems.length}/{addon.limit}
            </div>
          )}
        </div>
        <div className="mt-2">
          {Object.keys(addon.data).map((key: string, keyIndex: number) => (
            <div key={keyIndex} className="mb-2">
              <input
                type="checkbox"
                id={key}
                name={key}
                value={key}
                onChange={() => handleCheckboxChange(key, addon.data[key])}
                checked={selectedItems.includes(key)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />

              <label htmlFor={key} className="ml-2">
                {key}
                {convertCentsToDollars(addon.data[key]) === '0.00'
                  ? ''
                  : ` + ($${convertCentsToDollars(addon.data[key])})`}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default AddonComponent;
