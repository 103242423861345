import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import OrderItemCard from '../components/OrderItemCard';
import Button from '../components/Button';
import { Addon, AddonSelection, FoodItem } from '../interfaces/interfaces';
import { useCartStore } from './store/cartStore';
import { useMenuStore } from './store/menuStore';
import AddonComponent from '../components/AddonComponent';
import { useNavigate } from 'react-router-dom';

const variants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

function Addons({
  addons,
  handleAddonClick,
  selectedAddons,
}: {
  addons: Addon[];
  handleAddonClick: (addonName: string, item: string, price: number) => void;
  selectedAddons: Record<string, AddonSelection[]>;
}) {
  return (
    <motion.div
      className="mt-8 w-full max-w-md px-4 py-2"
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {addons.length > 0 && (
        <h1 className="text-xl font-semibold text-white">Addons</h1>
      )}

      {addons.map((addon: Addon, index: number) => (
        <AddonComponent
          key={index}
          addon={addon}
          handleAddonClick={handleAddonClick}
          selectedAddons={selectedAddons}
        />
      ))}
    </motion.div>
  );
}

export default function EditFoodItem() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const cart = useCartStore.getState().cart;
  const order = cart.find((item: FoodItem) => item.id === id);

  const menu = useMenuStore.getState().menu;
  const item = menu.find(
    (item: FoodItem) =>
      item.name === order?.name && item.description === order?.description
  );

  const handleAddonClick = (addonName: string, item: string, price: number) => {
    setSelectedAddons((prev) => {
      const currentSelection = prev[addonName] || []; // default to empty array
      const existingIndex = currentSelection.findIndex(
        (addon) => addon.item === item
      );
      if (existingIndex !== -1) {
        // Remove the existing addon
        const newSelection = [...currentSelection];
        newSelection.splice(existingIndex, 1);
        return {
          ...prev,
          [addonName]: newSelection,
        };
      } else {
        // Add the new addon
        return {
          ...prev,
          [addonName]: [
            ...currentSelection,
            {
              name: addonName,
              item: item,
              price: price,
              quantity: 1,
            },
          ],
        };
      }
    });
  };

  const [selectedAddons, setSelectedAddons] = useState<
    Record<string, AddonSelection[]>
  >(order?.selectedAddons || {});

  console.log(selectedAddons);

  console.log(order);
  useEffect(() => {
    console.log(selectedAddons);
    order!.selectedAddons = selectedAddons;
  }, [selectedAddons]);

  useEffect(() => {
    console.log(order);
  }, [order]);

  return (
    order &&
    item && (
      <div className="flex flex-col gap-6 bg-dark-black h-screen">
        {/* item card */}
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <OrderItemCard item={order} />
        </motion.div>

        <Addons
          addons={item.addon}
          handleAddonClick={handleAddonClick}
          selectedAddons={selectedAddons}
        />

        <div className="w-full px-4">
          <textarea
            name="message"
            id="message"
            className="w-full h-auto border-2 border-black rounded-md p-2 resize-none font-semibold"
            placeholder="Enter a custom message for your order..."
            onChange={(e) => (order.note = e.target.value)}
            defaultValue={order.note}
          ></textarea>
        </div>

        <div className="w-full flex justify-center items-center">
          <Button
            name="Save"
            onClick={() => {
              useCartStore.getState().updateCart(order);
              setTimeout(() => {
                navigate('/cart');
              }, 500);
            }}
          />
        </div>
      </div>
    )
  );
}
