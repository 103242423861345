import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion';

import { Addon, AddonSelection, FoodItem } from '../interfaces/interfaces';

import Button from '../components/Button';
import AddonComponent from '../components/AddonComponent';
import Dropdown from '../components/Dropdown';
import Recommendations from '../components/Recommendations';
import OrderItemCard from '../components/OrderItemCard';

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

function Addons({
  addons,
  handleAddonClick,
}: {
  addons: Addon[];
  handleAddonClick: (addonName: string, item: string, price: number) => void;
}) {
  return (
    <motion.div
      className="mt-8 w-full max-w-md px-4 py-2"
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {addons.length > 0 && (
        <h1 className="text-xl font-semibold text-white">Addons</h1>
      )}

      {addons.map((addon: Addon, index: number) => (
        <AddonComponent
          key={index}
          addon={addon}
          handleAddonClick={handleAddonClick}
        />
      ))}
    </motion.div>
  );
}

export default function CustomizeOrder() {
  const location = useLocation();
  const { order }: { order: FoodItem } = location.state;
  const [isRecommendation, setIsRecommendation] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [showTextarea, setShowTextarea] = useState<boolean>(false);

  // toggle isRecommendation arrow function
  const toggleIsRecommendation = () => {
    window.scrollTo(0, 0);
    setIsRecommendation((prev) => !prev);
  };

  const [selectedAddons, setSelectedAddons] = useState<
    Record<string, AddonSelection[]>
  >({});

  const handleAddonClick = (addonName: string, item: string, price: number) => {
    setSelectedAddons((prev) => {
      const currentSelection = prev[addonName] || []; // default to empty array
      const existingIndex = currentSelection.findIndex(
        (addon) => addon.item === item
      );
      if (existingIndex !== -1) {
        // Remove the existing addon
        const newSelection = [...currentSelection];
        newSelection.splice(existingIndex, 1);
        return {
          ...prev,
          [addonName]: newSelection,
        };
      } else {
        // Add the new addon
        return {
          ...prev,
          [addonName]: [
            ...currentSelection,
            {
              name: addonName,
              item: item,
              price: price,
              quantity: 1,
            },
          ],
        };
      }
    });
  };

  // check for size addon
  const checkSizeAddon = () => {
    // if size addon exists, check if it is selected
    if (order.addon.length > 0) {
      const sizeAddon = order.addon.find((addon) => addon.name === 'Size');
      if (sizeAddon) {
        const sizeAddonSelected = selectedAddons[sizeAddon.name];
        if (sizeAddonSelected && sizeAddonSelected.length > 0) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        setIsDisabled(false);
      }
    }
  };

  // Copy order object and modify
  const orderWithSelectedAddons = {
    ...order,
    id: uuidv4(),
    selectedAddons,
  };
  orderWithSelectedAddons.addon = [];

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsRecommendation(false);
  }, [order]);

  useEffect(() => {
    checkSizeAddon();
  }, [selectedAddons]);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkSizeAddon();
  }, []);

  return (
    <div className="bg-dark-black h-screen text-white">
      <div className="fixed bottom-5 right-5">
        <Dropdown />
      </div>
      {isRecommendation ? (
        <div className="flex justify-center items-center w-full pt-8">
          <div className="px-4 md:max-w-lg flex flex-col justify-center items-center gap-4">
            <Recommendations foodItem={order} />
            <Button name="Menu" navigateTo="/order" />
          </div>
        </div>
      ) : (
        <motion.div
          className={`flex flex-col items-center gap-5 px-5 w-full bg-dark-black ${
            order.addon.length > 0 ? '' : 'h-screen'
          }`}
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="flex flex-col items-center gap-5 px-5 lg:w-1/2">
            {/* item card */}
            <motion.div
              className=""
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <OrderItemCard item={order} />
            </motion.div>

            <Addons addons={order.addon} handleAddonClick={handleAddonClick} />

            <div className="w-full px-4">
              <textarea
                name="message"
                id="message"
                className="w-full h-auto border-2 border-black rounded-md p-2 resize-none font-semibold text-black"
                placeholder="Enter a custom message for your order..."
                onChange={(e) =>
                  (orderWithSelectedAddons.note = e.target.value)
                }
              ></textarea>
            </div>

            <div className="flex gap-2 pb-5">
              <Button name="Menu" navigateTo="/order" />
              <Button
                order={orderWithSelectedAddons}
                toggle={toggleIsRecommendation}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}
