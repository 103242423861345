import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import { useCartStore } from './store/cartStore';
import { useAppStore } from './store/AppStore';

import { FoodItem } from '../interfaces/interfaces';
import ItemCard from '../components/ItemCard';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import Recommendations from '../components/Recommendations';
import { SquareCheckout } from '../utils/utils';

export default function Cart() {
  const { getCart, cart } = useCartStore();

  useEffect(() => {
    getCart();
  }, []);

  let totalPrice = 0;
  cart.map((item: FoodItem) => {
    totalPrice += item.price / 100;
    Object.entries(item.selectedAddons).map(([key, value]: any) => {
      value.map((addon: any) => {
        totalPrice += addon.price / 100;
      });
    });
  });
  const tax = totalPrice * 0.06625;
  const grandTotal = totalPrice * 1.06625;

  return (
    <motion.div
      className={`flex justify-center items-center bg-dark-black text-white py-5 ${
        cart.length > 0 ? 'min-h-screen' : 'h-screen'
      }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col justify-center items-center space-y-4 w-full sm:max-w-xl">
        <h2 className="flex flex-row items-center justify-center gap-2 font-semibold text-3xl mb-4">
          Cart
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path
              d="M13.299 3.74c-.207-.206-.299-.461-.299-.711 0-.524.407-1.029 1.02-1.029.262 0 .522.1.721.298l3.783 3.783c-.771.117-1.5.363-2.158.726l-3.067-3.067zm3.92 14.84l-.571 1.42h-9.296l-3.597-8.961-.016-.039h9.441c.171-.721.46-1.395.848-2h-14.028v2h.643c.535 0 1.021.304 1.256.784l4.101 10.216h12l1.211-3.015c-.699-.03-1.368-.171-1.992-.405zm-6.518-14.84c.207-.206.299-.461.299-.711 0-.524-.407-1.029-1.02-1.029-.261 0-.522.1-.72.298l-4.701 4.702h2.883l3.259-3.26zm8.799 4.26c-2.486 0-4.5 2.015-4.5 4.5s2.014 4.5 4.5 4.5c2.484 0 4.5-2.015 4.5-4.5s-2.016-4.5-4.5-4.5zm-.469 6.484l-1.688-1.637.695-.697.992.94 2.115-2.169.697.696-2.811 2.867z"
              fill="white"
            />
          </svg>
        </h2>

        <div className="fixed bottom-5 right-5">
          <Dropdown />
        </div>

        <div className="w-full grid grid-cols-1 gap-4 px-3">
          {cart.map((item: FoodItem) => (
            <div key={item.id} className="flex flex-col gap-2">
              <ItemCard item={item} showButtons={true} showAddons={true} />
              <hr className="" />
            </div>
          ))}
        </div>

        <div className="w-full px-4">
          <textarea
            name="message"
            id="message"
            className="w-full h-auto border-2 border-black rounded-md p-2 resize-none font-semibold text-black"
            placeholder="Enter a message for your order..."
            onChange={(e) =>
              useAppStore
                .getState()
                .setMyValue('message', e.target.value.replace('"', ''))
            }
            defaultValue={useAppStore.getState().getMyValue('message')}
          ></textarea>
        </div>

        <div className="flex flex-col w-full px-4 space-y-2">
          <div className="flex justify-between items-center py-2">
            <p className="font-semibold text-xl">Total:</p>
            <p className="font-medium text-lg">${totalPrice.toFixed(2)}</p>
          </div>
          <div className="flex justify-between items-center py-2">
            <p className="font-semibold text-xl">Fees & Tax:</p>
            <p className="font-medium text-lg">${tax.toFixed(2)}</p>
          </div>
          <div className="flex justify-between items-center py-2 border-t-2 border-white mt-2">
            <p className="font-semibold text-2xl">Grand Total:</p>
            <p className="font-medium text-2xl">${grandTotal.toFixed(2)}</p>
          </div>
        </div>

        <div>
          {cart.length > 0 ? (
            <Button name="Checkout" onClick={() => SquareCheckout(cart)} />
          ) : (
            <Button name="Menu" navigateTo="/order" />
          )}
        </div>

        {cart.length > 0 && (
          <div className="px-4 pt-4 pb-16">
            <Recommendations foodItem={cart[0]} />
          </div>
        )}
      </div>
    </motion.div>
  );
}
