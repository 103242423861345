import { create } from 'zustand';

interface LocalStorageStore<T> {
  get: () => T | null;
  set: (value: T) => void;
}

export function createLocalStorageStore<T>(
  key: string,
  defaultValue: T
): LocalStorageStore<T> {
  return {
    get: () => {
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }
      return defaultValue;
    },
    set: (value) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
  };
}

interface MyStore {
  setMyValue: (key: string, value: string) => void;
  getMyValue: (key: string) => any;
}

export const useAppStore = create<MyStore>((set) => ({
  setMyValue: (key, value) => {
    createLocalStorageStore(key, value).set(value);
  },
  getMyValue: (key) => {
    return createLocalStorageStore(key, '').get();
  },
}));
