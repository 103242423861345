import React, { useEffect } from 'react';
import { FoodItem } from '../interfaces/interfaces';
import { useMenuStore } from '../pages/store/menuStore';
import OrderItemCard from './OrderItemCard';
import { tags } from '../utils/CategoryManager';
import Carousel from './Carousel';

interface Props {
  foodItem?: FoodItem;
}

export default function Recommendations({ foodItem }: Props) {
  const { getMenu, menu } = useMenuStore();

  useEffect(() => {
    getMenu();
  }, []);

  const recommendations = menu.filter((item) => {
    if (foodItem) {
      const category = foodItem.category.includes('Create Your Own')
        ? 'Create Your Own'
        : foodItem.category;
      const itemCategory = item.category.includes('Create Your Own')
        ? 'Create Your Own'
        : item.category;
      const tag = tags.find((tag) => tag.name === category);
      return tag?.recommendations.includes(itemCategory);
    }
  });

  return (
    <div>
      <Carousel items={recommendations} />
    </div>
  );
}
